<template>
  <div class="layout-topbar">
    <button class="p-link layout-topbar-button" @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true
      }"
    >
      <span class="mr-2 hidden md:inline">{{ profile.name }}</span
      ><i class="pi pi-user"></i>
    </button>

    <ul class="layout-topbar-menu hidden origin-top">
      <li>
        <button
          @click="$router.push('/profile')"
          class="p-link layout-topbar-button"
        >
          <i class="pi pi-user"></i>
          <span>Perfil</span>
        </button>
      </li>
      <li>
        <button @click="logout" class="p-link layout-topbar-button">
          <i class="pi pi-sign-out"></i>
          <span>Sair</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { logout, getProfile } from '@/services/auth'
import EventBus from '@/AppEventBus'

export default {
  data() {
    return {
      profile: {}
    }
  },
  async mounted() {
    this.profile = await getProfile()
    EventBus.on('updateProfile', profile => {
      this.profile = profile
    })
  },
  methods: {
    onMenuToggle(event) {
      this.$emit('menu-toggle', event)
    },
    logout() {
      logout(this.retornarAoLogin)
    },
    retornarAoLogin() {
      this.$router.replace('/login')
    }
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme
    }
  }
}
</script>
