<template>
  <div :class="containerClass" @click="onWrapperClick">
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <router-view :key="$route.fullPath" />
      </div>
      <AppFooter />
    </div>

    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';

export default {
  emits: ['change-theme'],
  data() {
    return {
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          items: [
            { label: 'Painel', icon: 'pi pi-fw pi-chart-line', to: '/painel' },
          ]
        },
        {
          label: 'Cadastros',
          items: [
            { label: 'Clientes', icon: 'pi pi-fw pi-users', to: '/clientes' },
            {
              label: 'Devedores',
              icon: 'pi pi-fw pi-money-bill',
              to: '/devedores',
            },
            {
              label: 'Política de negociação',
              icon: 'pi pi-fw pi-file',
              to: '/politica-de-negociacao',
            },
            {
              label: 'Dívidas',
              icon: 'pi pi-fw pi-money-bill',
              to: '/dividas'
            },
            { label: 'Carteiras', icon: 'pi pi-fw pi-money-bill', to: '/carteiras' },
            { label: 'Usuários', icon: 'pi pi-fw pi-user', to: '/usuarios' },
          ],
        },
        {
          label: 'Relatórios',
          items: [
            { label: 'Consultas', icon: 'pi pi-fw pi-search', to: '/reports/search' },
            { label: 'Confirmações', icon: 'pi pi-fw pi-eye', to: '/reports/confirm' },
            { label: 'Propostas', icon: 'pi pi-fw pi-file', to: '/reports/proposal' },
            { label: 'Acordos', icon: 'pi pi-fw pi-check-circle', to: '/reports/agreement' },
          ]
        },
        {
          label: 'Exportações',
          items: [
            { label: 'Histórias', icon: 'pi pi-fw pi-file', to: '/historias' },
          ]
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
          ' ',
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
      }

      return true;
    },
    changeTheme(event) {
      this.$emit('change-theme', event);
    },
  },
  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo.svg';
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive) this.addClass(document.body, 'body-overflow-hidden');
    else this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
.p-toast.p-toast-top-right {
  z-index: 1000;
  top: 7rem;
}
</style>
